import React, {useMemo} from "react";
import {FormProps, Typography, Button, Checkbox, Form, Space} from "antd";
import {
    ExportOutlined
} from '@ant-design/icons';

type FieldType = {
    'Agreed Software as a Service Agreement': boolean;
};

type Props = {
  fields: { [key: string]: any };
  onFinish: FormProps<FieldType>["onFinish"];
    isSubmitted?: boolean;
};

export const Record: React.FC<Props> = ({ fields, onFinish, isSubmitted }) => {
    const [form] = Form.useForm<{ 'Agreed Software as a Service Agreement': boolean; }>();
    const [submittable, setSubmittable] = React.useState<boolean>(false);
    const values = Form.useWatch([], form);

    React.useEffect(() => {
        form
            .validateFields({ validateOnly: true })
            .then(() => setSubmittable(true))
            .catch(() => setSubmittable(false));
    }, [form, values]);
    const isSigned = useMemo(() => Boolean(fields['Agreed Software as a Service Agreement']), [fields]);

  return (
    <Form form={form} onFinish={onFinish} autoComplete="off" layout="vertical" initialValues={{
        'Agreed Software as a Service Agreement': isSigned,
    }}>
      <Space direction="vertical" size="middle">
          <div>
              <Form.Item<FieldType>
                  style={{
                      marginBottom: 0
                  }}
                  rules={[
                      {
                          validator: (_, value) =>
                              value ? Promise.resolve() : Promise.reject(new Error('Please read and agree')),
                      },
                  ]}
                  name="Agreed Software as a Service Agreement"
                  valuePropName="checked"
              >
                  <Checkbox disabled={isSigned || isSubmitted}>
                      I agree to{" "}
                      <Typography.Link target="_blank" href={fields?.["Software as a Service Agreement"]}>
                          Software as a Service Agreement <ExportOutlined />
                      </Typography.Link>
                  </Checkbox>
              </Form.Item>
          </div>
          <Form.Item>
              <Button type="primary" htmlType="submit" disabled={!submittable || isSigned || isSubmitted}>
                  Confirm and finish
              </Button>
          </Form.Item>
      </Space>
    </Form>
  );
};
